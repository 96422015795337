<template>
  <div class="content">
    <div class="header">
      <div class="nav">
        <!-- {{ localStorage.getItem('pageName') }} -->
        <div class="data" @click="selectName">
          <div class="box">{{ navName }}</div>
        </div>

        <div class="item">
          <div class="boxs" v-for="(item,index) in oneList" :key="index">
            <a href="#">{{item.name}}</a>
            <div class="list" v-if="item.list">
              <div class="cons" v-for="(list,v) in item.list" :key="v">
                <span>{{list.name}}</span>
              </div>
            </div>
            <!-- <div class="list" v-if="item.list">
              <div class="cons" v-for="(list,v) in item.list" :key="v">
               <span>{{list.name}}</span>
              </div>
            </div> -->
          </div>
          <!-- <div class="boxs">
            <a href="#">首页官网</a>
            <ul>
              <li>
                <p>1</p>
              </li>
            </ul>
          </div>
          <div class="boxs">
            <a href="#">首页官网</a>
          </div>
          <div class="boxs">
            <a href="#">首页官网</a>
          </div> -->
        </div>

        <ul v-show="is_show">
          <li
            v-for="(item, index) in list"
            :key="index"
            @click="selectPage(item)"
          >
            <img :src="item.imgs" alt="" />
            <p>{{ item.name }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="zw"></div>
    <div class="datas">
      <div class="left">
        <h3>全部网址导航</h3>
        <div class="list">
          <ul>
            <li
              v-for="(item, index) in navList"
              :key="index"
              :class="navIndex == index ? 'xz' : ''"
              @click="chooseData(index)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <!-- :placeholder="info.con" -->
      <div class="right">
        <div class="search">
          <div class="top">
            <p
              :class="selectIndex == index ? 'xz' : ''"
              v-for="(list, index) in search"
              :key="index"
              @click="checkInfo(list, index)"
            >
              {{ list.title }}
            </p>
          </div>
          <div class="data">
            <input
              id="search-text"
              type="text"
              :placeholder="info.con"
              style="font-size: 15px"
              v-model="content"
              class="form-control smart-tips search-key"
            />
            <!-- <i class="iconfont icon-sousuo" @click="go(info.url)"></i> -->
            <p>搜索一下</p>
          </div>
          <div class="bottom">
            <p
              v-for="(list, index) in datas"
              :key="index"
              @click="
                info = list;
                infoIndex = index;
              "
              :class="infoIndex == index ? 'xz2' : ''"
            >
              {{ list.title }}
            </p>
          </div>
        </div>
        <div class="item">
          <div class="list" v-for="(item, index) in navList" :key="index">
            <h3 :id="'con_' + index">{{ item.name }}</h3>
            <ul>
              <li v-for="(list, key) in item.lists" :key="key">
                <a :href="list.url">
                  <div class="top">
                    <img v-if="list.imgs" :src="list.imgs" alt />
                    <p>{{ list.name }}</p>
                  </div>
                  <div v-show="list.con" class="con">{{ list.con }}</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <v-header /> -->
    <!-- <div class="fast">
      <div
        class="list"
        :style="'background: url(' + list.imgs + ') 100%'"
        v-for="list in cardList"
        :key="list"
        @click="go(list)"
      >
        <p>{{list.name}}</p>
      </div>
    </div>
    <div class="select">
      <p v-for="(item,index) in list" :key="index"
      :class="selectIndex == index ? 'xz' : ''"
      @click="selectIndex = index"
      >{{item.name}}</p>
    </div>-->

    <!-- <div class="item">
        <ul>
          <li v-for="(v,key) in dataList" :key="key" @click="go(v)">
            <img :src="v.imgs" alt="">
            <a :href="v.url" target="__blank">{{v.name}}</a>
          </li>
        </ul>
    </div>-->
    <!-- <div class="item">
      <div class="list">
        <ul>
          <li v-for="(v,key) in dataList" :key="key" @click="go(v)">
            <img :src="v.imgs" alt="">
            <a :href="v.url" target="__blank">{{v.name}}</a>
          </li>
        </ul>
      </div>
    </div>-->
  </div>
</template>

<script>
// import axios from "axios";
// import header from "@/components/header.vue";i
import { getList as getOneList } from '@/api/one'
import { getFilmCard } from "@/api/film";
import { getList, pageNavList } from "@/api/datas";
// getSearchInfo getSearch searchList
import { getSearchInfo, getByList } from "@/api/search";
export default {
  data() {
    return {
      list: [],
      datas: [],
      cardList: [],
      dataList: [],
      navList: [],
      oneList: [],
      selectIndex: 0,
      navIndex: 0,
      infoIndex: 0,
      navName: "",
      is_show: false,
      info: {},
    };
  },
  created() {
    // alert(localStorage.getItem('pageName'))
    if (localStorage.getItem("pageName")) {
      this.navName = localStorage.getItem("pageName");
    } else {
      this.navName = "AI导航";
    }

    this.getList();
    this.getFilmCard();
    // this.getSearch();
    this.getByList();
    this.getOneList()
  },
  watch: {
    selectIndex(val) {
      this.dataList = this.list[val].lists;
    },
  },
  mounted() {},
  methods: {
    handleScroll() {},
    selectName() {
      if (this.is_show) {
        this.is_show = false;
      } else {
        this.is_show = true;
      }
    },
    getOneList() {
      getOneList().then(res => {
        this.oneList = res.data
      })
    },
    // 搜索开始
    async getByList(id) {
      await getByList(id).then((res) => {
        console.log(res);
        // alert(JSON.stringify(res))
        this.search = res.data;
        this.getSearchInfo(res.data[0]["id"]);
      });
    },

    // 搜索结束
    go(list) {
      window.open(list.url);
    },
    // go_url(url) {
    //   this.ipdizhi();
    //   if (!this.content) {
    //     return this.$message.error("请输入搜索内容");
    //   }

    //   searchList({
    //     title: this.content,
    //     ip: this.ip,
    //     type: this.info.title,
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       window.open(url + this.content);
    //     }
    //   });
    // },

    selectPage(item) {
      // alert(item.name)
      this.navName = item.name;
      localStorage.setItem("pageName", item.name);
      this.getByList(item.id);
      this.pageNavList(item.name);

      this.is_show = false;
    },
    chooseData(index) {
      this.navIndex = index;
      var ids = document.querySelector("#con_" + index);
      if (ids) {
        window.scrollTo({
          top: ids.offsetTop,
          behavior: "smooth",
        });
      }
    },
    checkInfo(list, index) {
      console.log(list);
      this.getSearchInfo(list.id);
      this.selectIndex = index;
    },
    // getSearch() {
    //   getSearch().then((res) => {
    //     if (res.code == 200) {
    //       // this.search = res.data.list;
    //       // this.getSearchInfo(res.data.list[0]["id"]);
    //     }
    //   });
    // },
    getSearchInfo(id) {
      getSearchInfo(id).then((res) => {
        if (res.code == 200) {
          this.datas = res.data.list;
          this.info = res.data.list[this.infoIndex];
        }
      });
    },
    // ipdizhi() {
    //   fetch("https://api.ipify.org?format=json")
    //     .then((response) => response.json())
    //     .then((data) => {
    //       this.ip = data.ip;
    //     });
    // },
    getList() {
      getList().then((res) => {
        this.list = res.data;
        // this.navName = res.data[0]["name"];
        this.pageNavList(localStorage.getItem("pageName"));
        this.getByList(res.data[0]["id"]);
      });
    },
    getFilmCard() {
      getFilmCard().then((res) => {
        this.cardList = res.data;
      });
    },
    pageNavList(name) {
      pageNavList(name).then((res) => {
        console.log(res);
        this.navList = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  min-height: 100vh;
  background: #f5f6f7;
  .header {
    width: 100%;
    height: 60px;
    background: rgba(65, 127, 249, 0.9);
    position: fixed;
    z-index: 9;
    .nav {
      width: 1450px;
      height: 100%;
      margin: auto;
      position: relative;
      display: flex;
      align-items: center;
      .item {
        display: flex;
        position: relative;
        .boxs {
          .list {
            width: 500px;
            height: auto;
            position: absolute;
            background: #fff;
            box-shadow: 0 10px 30px 0 rgba(70, 86, 125, 0.15);
            top: 40px !important;
            left: -100px !important;
            padding-top: 20px;
            border-radius: 10px;
            display: none;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            // flex-wrap: wrap;
            padding-left: 50px;
            padding-right: 50px;
            // display: flex;
            // justify-content: center;
            .cons {
              // width: 32%;
              list-style: none;
              // display: flex;
              // flex-direction: column;
              // justify-content: space-between;
              // align-items: center;
              cursor: pointer;
              margin-bottom: 15px;
              span {
                width: auto;
                color: #000;
              }
              img {
                width: 45px;
                height: 45px;
                margin-bottom: 10px;
              }
              p {
                font-size: 13px;
              }
            }
          }
          &:hover .list {
            display: block;
          }
        }
        a {
          text-decoration: none;
          color: #fff;
          font-size: 15px;
          margin-right: 50px;
        }
      }
      .data {
        width: 25%;
        margin-left: 150px;
        .box {
          width: auto;
          padding-left: 20px;
          padding-right: 20px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.1);
          color: #fff;
          display: inline-block;
          cursor: pointer;
        }
      }
      ul {
        width: 330px;
        height: auto;
        position: absolute;
        background: #fff;
        box-shadow: 0 10px 30px 0 rgba(70, 86, 125, 0.15);
        top: 60px;
        left: 150px;
        padding-top: 20px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        li {
          width: 32%;
          list-style: none;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          margin-bottom: 15px;
          img {
            width: 45px;
            height: 45px;
            margin-bottom: 10px;
          }
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
  .zw {
    height: 80px;
  }
  .datas {
    width: 1450px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .left {
      width: 15%;
      position: fixed;
      h3 {
        width: 90%;
        height: 45px;
        line-height: 45px;
        margin-bottom: 10px;
        background: #fff;
        border-radius: 8px;
        font-size: 15px;
        text-indent: 10px;
      }
      .list {
        li {
          width: 90%;
          border-radius: 5px;
          height: 40px;
          line-height: 40px;
          list-style: none;
          margin-bottom: 10px;
          font-size: 15px;
          text-indent: 20px;
          cursor: pointer;
        }
        .xz {
          background-color: rgba(65, 127, 249, 0.9);
          color: #fff;
        }
      }
    }
    .right {
      width: 85%;
      height: 100%;
      position: relative;
      left: 18%;
      // .search {
      //   width: 100%;
      //   height: 350px;
      //   background: rgba(65,127,249,0.9);
      //   border-radius: 5px;
      // }
      .search {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgba(65, 127, 249, 0.9);
        border-radius: 5px;
        .top {
          display: flex;
          margin-bottom: 20px;
          p {
            color: #fff;
            margin-left: 10px;
            margin-right: 10px;
            cursor: pointer;
          }
        }
        .data {
          position: relative;
          #search-text {
            width: 800px;
            height: 35px;
            border-radius: 5px;
            // color: #fff;
            border: 1px solid rgba(255, 255, 255, 0.3);
            padding: 9px 20px;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            background-color: #fff;
            outline: none;
          }
          i {
            font-size: 20px;
            color: #000;
            font-weight: 800;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
          p {
            width: 100px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: #ff6000;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 0px 5px 5px 0px;
            cursor: pointer;
            font-weight: 700;
          }
        }
        .bottom {
          display: flex;
          margin-top: 15px;
          p {
            color: #fff;
            margin-left: 10px;
            margin-right: 10px;
            cursor: pointer;
            font-size: 15px;
          }
        }
      }
      .item {
        width: 100%;
        h3 {
          height: 70px;
          line-height: 95px;
          font-size: 20px;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 16%;
            height: auto;
            background: #fff;
            list-style: none;
            margin-top: 10px;
            border-radius: 5px;
            padding-bottom: 20px;
            a {
              width: 100%;
              height: 100%;
              text-decoration: none;
              background: #fff;
              .top {
                height: 50px;
                display: flex;
                align-items: center;
                img {
                  // width: 30px;
                  height: 25px;
                  margin-left: 12px;
                }
                p {
                  font-size: 15px;
                  color: #000;
                  font-weight: 800;
                  text-indent: 10px;
                }
              }
              .con {
                width: 90%;
                font-size: 13px;
                color: #8f8f8f;
                line-height: 20px;
                // padding-top: 10px;
                height: 36px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin: auto;
              }
            }
          }
          .xz {
            background-color: #ff6000;
            color: #fff;
          }
        }
      }
    }
  }
}

// .content {
//   background: #f5f6f7;
//   min-height: 100vh;

//   .fast {
//     width: 1400px;
//     margin: auto;
//     display: flex;
//     justify-content: space-between;
//     position: relative;
//     top: -60px;
//     .list {
//       width: 13.5%;
//       height: 95px;
//       border-radius: 5px;
//       display: flex;
//       justify-content: center;
//       flex-direction: column;
//       align-items: center;
//       cursor: pointer;
//       p {
//         color: #fff;
//       }
//     }
//   }
//   .select {
//     width: 1400px;
//     margin: -20px auto;
//     display: flex;
//     font-size: 17px;
//     color: #000;
//     p {
//       width: 100px;
//       height: 40px;
//       line-height: 40px;
//       margin-right: 20px;
//       font-size: 15px;
//       background: #fff;
//       color: #000;
//       text-align: center;
//       font-weight: 500;
//       cursor: pointer;
//       border-radius: 5px;
//       transition: 0.3s;
//       &:hover {
//         background: #ff6000;
//         color: #fff;
//       }
//     }
//     .xz {
//       background: #ff6000;
//       color: #fff;
//       text-align: center;
//     }
//   }
//   .banner {
//     .bg {
//       overflow: hidden;
//       width: 100%;
//       height: 280px;
//       background: #ccc url("http://guozhivip.com/images/bc0.jpg") no-repeat;
//       background-size: cover;
//     }
//   }
//   .item {
//     width: 1400px;
//     margin: 0px auto;
//     .list {
//       width: 100%;
//       &-nav {
//         width: 100%;
//         height: 50px;
//         line-height: 50px;
//         border-bottom: 1px solid #f5f6f7;
//       }
//       ul {
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: space-between;
//         margin-top: 40px;
//         li {
//           width: 19.5%;
//           height: 45px;
//           line-height: 45px;
//           list-style: none;
//           background: #fff;
//           display: inline-block;
//           display: flex;
//           align-items: center;
//           margin-bottom: 6px;
//           border-radius: 3px;
//           cursor: pointer;
//           a {
//             text-decoration: none;
//             font-size: 14px;
//             color: #000;
//           }
//           img {
//             height: 20px;
//             margin-right: 10px;
//             margin-left: 15px;
//           }
//         }
//       }
//     }
//   }
// }
</style>