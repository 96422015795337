import request from '@/utils/request'

export function getList() {
    return request({
        url: '/data/pageList' ,
        method: 'get'
    })
}

export function pageNavList(f_name) {
    return request({
        url: '/data/pageNavList?f_name=' + f_name,
        method: 'get'
    })
}

// 获取数据列表
// export function getDataList(pageSize,pageNumber) {
//     return request({
//         url: '/data/getDataList?pageSize=' + pageSize + '&pageNumber=' + pageNumber,
//         method: 'get' 
//     })
// }

// export function getDetails(id) {
//     return request({
//         url: '/data/details?id=' + id,
//         method: 'get'
//     })
// }

// export function deleteData(id) {
//     return request({
//         url: '/data/details?id=' + id,
//         method: 'detale'
//     })
// }