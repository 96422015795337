import request from '@/utils/request'

export function getList() {
    return request({
        url: '/data/getFilmList' ,
        method: 'get'
    })
}

export function getFilmCard() {
    return request({
        url: '/data/getFilmCard',
        method: 'get'
    })
}